.bl-prodoverview {
    .items {
        display:grid;
        grid-template-columns:repeat(2, 1fr);
        gap:var(--gap1);

        @include breakpoint(medium down) {
            grid-template-columns:repeat(1, 1fr);
        }    
    }

    .ovimg {
        aspect-ratio: 6 / 4;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height:100%;
            object-fit: cover;
            object-position: center center;

            transition: transform 0.4s;
        }
    }
    a {
        color:inherit;
    }
    a:hover .ovimg img {
        transform: scale(1.1);
    }

    h2 {
        margin-top:0.25em;
    }
}