//Standardabstand zwischen zwei Bloecken
.cblock + .cblock {
    margin-top:2em;
}

/*
//Spezifische Abstaende
.ws1 {
    .bl-richtext + .bl-slider {
        margin-top:2em;
    }
    .bl-slider + .bl-linkboxes {
        margin-top:2em;
    }
}

.bl-richtext + .bl-referenzlist {
    margin-top:2em;
}
*/