section {
    --baseunit: 1.5rem;
    @include breakpoint (small down) {
        --baseunit: 0.75rem;
    }
    @for $i from 1 through 5 {
        &.pt_#{$i} {
            padding-top:calc(#{$i} * var(--baseunit));
        }
        &.pb_#{$i} {
            padding-bottom:calc(#{$i} * var(--baseunit));
        }

        &.mt_#{$i} {
            margin-top:calc(#{$i} * var(--baseunit));
        }
        &.mb_#{$i} {
            margin-bottom:calc(#{$i} * var(--baseunit));
        }
    }
}