.off-canvas {
  padding-top: 3em;
  max-width: 100%;
}

#menu-offcanvas .menu li.active > a::after {
  background-color: #666 !important;
}

#menu-offcanvas .dropdown.menu > li > a {
  background: inherit; //issue #18
}