.bl-referenzlist {
    .tabs {
        background-color: $black;
    }
    .tabs a {
        font-size:inherit;
        color:$white;
    }
    .tabs li.is-active,
    .tabs-title a:hover {
        background-color: #666;
    }

    @include breakpoint(small down) {
        .tabs li {
            float:none;
        }
    }

    .ctrreflist {
        padding:1em;
        border:1px solid #ccc;
        border-top:none;

        overflow-x: auto; //responsive
    }

    table {
        th {
            text-align: left;
            font-weight: 500;
        }
        th, td {
            padding-right: 1.5em;
            vertical-align: top;
            @include breakpoint (small down) {
                padding-bottom:0.75em;
            }
        }
    }
}