.bl-team {
    .items {
        display:grid;
        grid-template-columns:repeat(4, 1fr);
        gap:var(--gap1);

        @include breakpoint(medium down) {
            grid-template-columns:repeat(2, 1fr);
        }    
        @include breakpoint(small down) {
            grid-template-columns:repeat(1, 1fr);
        }
    }

    .item {
        .foto {
            display:block;
            width: 100%;
            margin-bottom: 0.5em;

            aspect-ratio: 1 / 1.3;
            object-fit: cover;
            object-position: center 30%;
        }
    }
}