//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

main {
  //Dynamic Font Size (mobile, desktop)
  @include font-size(1, 1.5);
}
header {
  @include font-size(1, 1.5);
}
footer {
  @include font-size(1, 1.5);
}

a:focus {
  outline: none;
}

h1 {
  @include font-size(2, 2.5);
}
h2 {
  @include font-size(1.5, 2);
}