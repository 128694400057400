.bl-linkboxes {
    .items {
        display:grid;
        grid-template-columns:repeat(3, 1fr);
        gap:var(--gap1);

        @include breakpoint(medium down) {
            grid-template-columns:repeat(1, 1fr);
        }    
    }
    .item {
        background-color: $light-gray;
        padding:1em;
        border-radius: var(--borderradius1);

        border-top: 10px solid $farbe1;
        border-bottom: 10px solid $farbe1;
        box-shadow: 0 2px 7px 0 rgba(10, 10, 10, 0.1);

        @include breakpoint(small down) {
            border-width: 5px;
        }
    }
    .item {
        display:flex;
        flex-direction: column;
        .infos {
            flex:1 0 auto;
        }
        .buttonbar {
            flex:0 0 auto;
        }
    }
}
