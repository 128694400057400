.buttonbar {

}
.button {
  display: block;
  width: fit-content;
  font-weight: 700;
  padding:0.75em 1.5em;
  
  //color:$black;
  //background-color: $yellow;
  color:white;
  background-color: $farbe1;

  border-radius: var(--borderradius1);
  transition:background-color 0.2s;

  &:hover, &:active, &:focus {
    //color:$black;
    //background-color: darken($yellow, 5%);
    color:white;
    background-color: darken($farbe1, 5%);
  }
}
