.bl-slider {
    --swiper-theme-color: white;

    .swiper-button-prev,
    .swiper-button-next {
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);

        @include breakpoint(small down) {
            display:none;
        }
    }

    img {
        display: block;
        width:100%;
    }

    .infopanel {
        background-color:$light-gray;
        padding:1em;
        
        .date {
            font-size:0.6em;
        }
        h2 {
            font-size:1em;
            margin-bottom: 0;
            font-weight: 700;
        }
        .text {
            //font-size:0.8em;
        }
    }    
}

.bl-slider.homeslider {
    --swiper-pagination-color:black;

    a {
        color:inherit;
        text-decoration: inherit;
    }
}