.herostart {
    height:100vh;
    height:100lvh;

    position: relative;

    background-color: #eee;
    background-size: cover;
    background-position: center center;

    text-align: center;

    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .scrolldownindicator {
        display:none;

        position: absolute; //fixed
        left:50%;
        transform: translateX(-50%);
        top:calc(100vh - rem-calc(100));
        height:rem-calc(100);

        .arrowimg {
            display:block;
            width:rem-calc(50);
            margin:0 auto;
        }
    }

}