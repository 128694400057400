.aktuellitem {
    h1 {
        margin-bottom:0;
    }
    .date {
        font-size:0.75em;
        margin-bottom: 1em;
    }

    &.contentblocks0 {
        //no content blocks => imported from old site

        img {
            padding-top:0.15em;
            padding-right:0.15em;
            max-width:33%;
        }
    }
}

.aktuelldetail {
    //z.B. http://deag.test/neubau-auf-kurs
    h1 {
        margin-bottom:1em;
    }
}

.bl-aktuellarchiv {
    table {
        td {
            padding-right:1em;
        }
    }
}