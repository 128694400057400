footer {
  background-color: black;
  color:white;

  padding: rem-calc(50 0);
  a {
    color: inherit;
    &:hover {
      color:$blackbglinkhover;
    }
  }

  @include breakpoint(large) {
    .wconstrain {
      display: flex;
      justify-content: space-between;
    }

    .ctrcopyright {
      text-align: right;
    }
  }

  @include breakpoint(medium down) {
    .ctrcopyright {
      margin-top:2em;
    }
  }
}

