.textwithimage {
    .ctrtext {
    }
    .ctrimage {
        margin-top: 0.35em;
        /*aspect-ratio: 1 / 1;*/

        /*
        display:flex;
        justify-content: center;
        align-items: center;
        */

        img {
            width: 100%;
        }
    }

    @include breakpoint(large) {
        display:flex;
        align-items: stretch;        
        gap:rem-calc(50);

        .ctrtext {
            width:50%;
        }
    
        .ctrimage {
            width:50%;
        }

        &.imgpos-left {
            .ctrimage {
                order: -1;
            }
        }
    } 
}

