.wconstrain {
    margin-left: auto;
    margin-right: auto;

    --maxw: calc(100% - 3rem);
    @include breakpoint (medium) {
        --maxw: calc(100% - 6rem);
    }
    width: min(75rem, var(--maxw));

    &.w_full {
        width:100%;
    }

    /*
    @media (min-width:1300px) {
        &.w_l {
            width: calc(100% - 24rem);
        }
        &.w_m {
            //https://stackoverflow.com/questions/62280339/css-min-function-to-sass
            --temp0: calc(100% - 24rem);
            width: min(70rem, var(--temp0));
        }
    }
    */
}
