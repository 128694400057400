.bl-markenlist {
    .items {
        display:grid;
        gap:var(--gap1);

        grid-template-columns:repeat(2, 1fr);
        @include breakpoint(large) {
            &.countlt5 {
                //less than 5
                grid-template-columns:repeat(4, 1fr);
            }
            &.countgte5 {
                //5 or more
                grid-template-columns:repeat(5, 1fr);
            }
        }    
    }
    .item {
        aspect-ratio: 1;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;

        .mimg {
            width:80%;
            height:50%;
            object-fit: contain;
            object-position: center center;
        }
    }
    h2 {
        margin-bottom:0.5em;
    }
}