// settings
$remBase: 16; //don't change that, otherwise you have to change it everywhere plus everything which is calculated with rem changes too...
$widthMin: 480; //set small breakpoint. unitless value in pixels.
$widthMax: 1200; //set large breakpoint. unitless value in pixels.

@mixin font-size($valueMin, $valueMax) {
  // turn mixin parameters into rem value.
  $fontMin: $valueMin * $remBase;
  $fontMax: $valueMax * $remBase;

  // set minimum font size. give it rem value.
  font-size: $valueMin * 1rem;

  // set font size between min and max breakpoints.
  @media (min-width: #{$widthMin}px) {
    font-size: calc(
      #{$fontMin}px + (#{$fontMax} - #{$fontMin}) *
        ((100vw - #{$widthMin}px) / (#{$widthMax} - #{$widthMin}))
    );
  }

  // set font size above max breakpoint
  @media (min-width: #{$widthMax}px) {
    font-size: $valueMax * 1rem;
  }
}
